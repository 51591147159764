<template lang="html">
  <EulaEn />
</template>

<script>
import EulaEn from "../components/eula/EulaEn.vue";

export default {
  components: {
    EulaEn
  },
  created() {
    // this.$store.commit('muttHideHeader', "wa")
  }
}
</script>

<style lang="scss" scoped>
  .eula {
    text-align: justify;

    b {
      font-weight: bold;
    }
  }
  .inner {

    @media screen and (max-width: 599px) {
      margin: 20px 0 40px;
    }
  }
</style>
