<template lang="html">
  <EulaEn />
</template>

<script>
import EulaEn from "../components/eula/EulaEn.vue";

export default {
  components: {
    EulaEn,
  },
};
</script>

<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>
