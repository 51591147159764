<template>
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <b>End-User License Agreement</b> <br>
        <b>Effective date: October 21, 2022</b> <br><br>
        <b>Definitions</b> <br><br>
        Please read this End-User License Agreement (EULA) carefully before clicking the "I Agree" button, downloading or using any of the Nexgen English Online Co. (“Nexgen”) applications (Myneo, neo Study App, neo Study Online, neo Classroom, and neo LIVE), Nexgen courseware, Nexgen websites or Nexgen services (“Application”). Nexgen is the owner of the Application and is a registered California corporation. This EULA is a legal agreement between an individual or single entity (”You”, ”Your”) and Nexgen and it governs your use of the Application, Courseware, and Services made available to you by Nexgen. By clicking the "I Agree" button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this EULA. The use of the term organization in this agreement refers to any third-party school, business partner, reseller or other individual or firm that is administrating an English Language course or training based on the Nexgen Application and materials. If you do not agree to the terms of this EULA, do not click on the "I Agree" button and do not download or use the Application.

        <br><br><b>License</b><br><br>
        The Application is licensed, not sold, to you by Nexgen. Nexgen grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application, solely for the purposes established in the terms of this EULA. The Application is made available for individual use only and is not transferable. Upon registration, the service will execute a confirmation procedure that will conclude with “Account Confirmation” that will proclaim that you are the owner of your account after you “Confirm”. If you become aware of any unauthorized use of your account immediately notify Nexgen. Nexgen will not be liable for any loss or damage arising from your failure to comply with this provision. Your rights to use the Application are specified in this EULA, and Nexgen retains all rights not expressly granted to you in this EULA. You may not copy, reproduce, distribute, or exploit any content, code, data, materials, or design, including but not limited to any copyright, patent rights, database rights, or any other intellectual property and proprietary rights therein. Use of Nexgen applications, courseware, or services does not grant you ownership of any Nexgen content, code, data, or materials. Any distribution, publishing, or exploitation of Nexgen Application is strictly prohibited unless you have received prior written permission from Nexgen. Nothing in this EULA constitutes a waiver of Nexgen’s rights under the United States and International Copyright law, or any other federal or state laws, copyright laws, or any other applicable laws of other nations.

        <br><br><b>Third-Party Services</b><br><br>
        The Application may display, include, or make available third-party content (including data, information, applications, and other products services) or provide links to third-party websites or services (“Third-Party Services”). You acknowledge and agree that Nexgen shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Nexgen does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you and your access; use them entirely at your own risk and subject to such third parties’ terms and conditions. 

        <br><br><b>Terms of Service</b><br><br>
        1. Using the Application, you study towards the successful completion of a Common European Framework of Reference for Languages (“CEFR”) level and can earn a CEFR correlated English Certificate.<br>
        2. The Application requires you to fulfill Study Points and Coaching Points. Study Points are earned by studying with the Application. Study Point requirements depend on the Certificate level and can be found in the Application. Nexgen suggests you conduct one online coaching session with the Application every two weeks, where each session will earn you Coaching Points. The Coaching Points requirements are found in the Application and Nexgen’s Frequently Asked Questions (“FAQ”).<br>
        3. Once you fulfill the Study and Coaching Points requirements, Nexgen will ask you to take the level’s Certificate Test in the Application. At this point, you can choose to take the Certificate Test or study review lessons in preparation before the Certificate Test. <br>
        4. If you fail the Certificate Test, Nexgen will require you to study review lessons in the Application. The level’s Certificate Test will open again after you have completed the required review lessons. Nexgen will allow a level’s Certificate Test to open a maximum of three (3) times.<br>
        5. After you pass a Certificate level Certification Test, Nexgen will make available your earned certificate once you have paid the level’s fees.<br>
        6. Nexgen will make your certificate available through the Application. You may choose to print the certificate to present to your school, university, or employer. <br>
        7. Nexgen’s CEFR correlated certificates are issued by the education company DynEd International, Inc., based in San Jose, California.<br>
        8. Nexgen provides a Session Simulator, an exclusive tool that you can use to simulate an online coaching session on the Application. Running this step is mandatory to determine if your internet connection is adequate to connect and interact in an online coaching session. This step should be executed in the environment where the booked session will take place. In a case where the internet connection does not deliver sufficient bandwidth or stability, Nexgen suggests you switch to a faster or more stable Internet connection. Nexgen is not liable for any connection disturbance while trying to connect or during ongoing sessions. <br>
        9. If our trained coach does not show up for a scheduled online coaching session or is late by five minutes or more, Nexgen will facilitate and make available a replacement coaching session.<br>

        <br><b>Certificate Guarantee</b><br><br>
        1. Nexgen also offers a guarantee that in a specific number of months you will successfully pass the procured level’s Certificate Test and be issued a CEFR correlated certificate. See Nexgen’s FAQ for guaranteed study times.
        <br>
        2. To qualify for the guarantee, you must earn a predetermined number of Study Points each week and complete a minimum number of Coaching sessions per level. See Nexgen’s FAQ for Study Points and Coaching session requirements. <br>
        3. If you fail your level’s first Certification Test on or before the last day of the guaranteed period for your study level, Nexgen will allow you to book another online coaching session so that you can be better prepared for a second try at the level’s Certification Test.<br>
        4. Nexgen’s guarantee is only to award free online coaching session(s) for the first-time failure of a level’s Certificate Test. Subsequent Certificate Test failures, of the same level, do not result in an additional online coaching session(s).  If you have completed all the requirements for the guarantee and fail the online tests, Nexgen will contact you to receive a personal, online interview with a testing expert.<br>
        5. If your Certificate Test opens after the last day of the guaranteed period for your study level and you fail the Certificate Test on your first try, Nexgen will not facilitate any additional online coaching sessions.<br>
        6. <b>SPECIAL NOTE: This guarantee is only valid for self-study programs that use Nexgen’s coaches and booking systems provided by Nexgen. Contact your Nexgen Authorized Organization (hereinafter NAO) to understand their offerings and programs.</b><br>

        <br><b>Privacy Policy</b><br><br>
        The Nexgen privacy policy covers the collection and use of personal information that may be collected by Nexgen anytime you interact with Nexgen, such as when you visit our website, when you use Nexgen products and services, or when you contact our support representatives. <br><br>

        To ensure that all aspects of the Application are functional various hardware permissions are required. These include reading and writing to SD card permission, network access permission, microphone permission, or audio recording permission. If the end-user does not want to grant these permissions the end-user can turn off "Read and Write SD Card Permission," "Network Access Permission," and "Microphone Permission;" however, this will inhibit the Application from operating.<br><br>

        1. <b>Why does Nexgen collect personal information?</b> Nexgen collects personal information to help deliver a superior level of service and support. In addition, your personal information helps to keep you informed about product announcements, updates, and special offers.  If you subscribe to Nexgen electronic mailing lists, Nexgen may periodically send you the latest information on products and services. You can choose to unsubscribe at any time.  <br>
        2. <b>What information does Nexgen collect?</b> Your personal information helps to give you better service in several ways. At such times, Nexgen may collect personal information relevant to the situation, such as your name, phone number, email address, information about neo product(s) you own, such as date of purchase, and information relating to a support or service issue.<br>
        3. <b>Does Nexgen disclose your information?</b> Nexgen takes your privacy very seriously. When you use neo product or service, you trust Nexgen with your information. <u>Nexgen does not share personal information with companies, organizations, or individuals outside of Nexgen (and affiliated companies) unless it is with your consent or if it is required by law. </u><br>
        4. <b>How does Nexgen protect your personal information?</b> Nexgen takes all reasonable precautions, including administrative, technical, and physical measures, to safeguard your personal information against loss, theft, misuse, as well as unauthorized access, disclosure, alteration, and destruction.<br>
        5. <b>Region-specific privacy and data compliance?</b> Nexgen products and services are completely in compliance, at the time of the Effective Date, with the following Data Privacy regulations:<br>

        <div style="margin:0 0 0 20px;">
          a. GDPR, General Data Protection Regulation (EU)<br>
          b. FERPA, Family Educational Rights and Privacy Act (US)<br>
          c. COPPA, Children’s Online Privacy Protection Act (US)<br>
          d. DSL, Data Security Law  “ Personal Information Protection Act” (China)<br>
          e. PDPA, Personal Data Protection Act (Singapore)<br>
          f. APPI, Act on the Protection of Information (Japan)<br>
          g. PIPEDA, Personal Information Protection and Electronic Document Act (Canada)<br>
          h. CCPA, California Consumer Privacy Act (US)<br>
          i. CalOPPA, California Online Privacy Protection Act (US)<br>
        </div>
        6. <b>Removal of Personal Identifiable Information</b> Nexgen retains the minimum Personal Identifiable Information (“PII”) to allow controlled access to our services and enable the validation of earned certificates. However, if you wish to OPT-OUT and to have your PII removed from our databases, you may apply to legal@nexgenenglishonline.co requesting the removal of your PII by stating, “I have read, understand, and agree with the Removal of Personal Identifiable Information clause in Nexgen’s End-User License Agreement.” Removal of your PII will occur as soon as possible following verification that the request is legitimate. There may be instances where Nexgen is required to retain your PII to comply with applicable legal obligations, or to resolve disputes. When a request for Removal of Personal Identifiable Information has been satisfied before you complete your course, it will not be possible for you to continue studying. Further, Nexgen will not be able to verify nor be able to reissue any earned neo certificates if when your PII has been removed. In the instance that you wish to have your data removed and you are an active student enrolled within an NAO, then that NAO should be contacted and the removal request be directed to them. However, if you are no longer enrolled with the said NAO, you inherit the right to directly request Nexgen to remove your PII data, while the NAO loses the right of access to your Nexgen-held data. However, any NAO that you may have been enrolled with has a separate legitimate copy of your PII data that was held for administrative purposes. You will need to contact that NAO to remove that data.<br>

        <br><b>Privacy Concerns</b><br><br>
        If you have any questions about our Customer Privacy Policy or data processing methodology, please send an email to legal@nexgenenglishonline.co<br><br>

        <br> <b>Independent Student Registration and Requirements </b> <br><br>
        1. You must register and pay a certain fee in to obtain full access to Nexgen Application and functionalities. You will be asked to provide your personal information and data (“Personal Information”) as part of the process.<br>
        2. Before payment, You will be asked to purchase a Certificate at your next level, that defines the fee you will be charged. NAO’s may offer different payment plans valid in your region.<br>
        3. Once you have finished the registration and payment process, Nexgen will require you to create a single login (the “neo Universal Login”) that will be used to access its Applications.<br>
        4. To begin to study, you have to download the neo Study App and Myneo App to a device that meets the requirements found here https://nexgenenglishonline.co/support<br>
        5. By obtaining an Account through the registration and payment process, you also secure the coaching tokens needed, depending on your package selection, and you will need to schedule online coaching sessions with our trained coaches on the Myneo App. <br>

        <div style="margin:0 0 0 20px;">
          5.1 Five coaching sessions are included given by Nexgen per each certification level for all individuals that have paid for Nexgen Live access and are needed to fulfill Coaching Points requirements. Those with Nexgen Live should you not show up during a booked session that caused you to not earn coaching points, Nexgen will not be responsible for your failure to comply with this provision. In this case, you must buy an additional session to fulfill the coaching points requirement.<br>
          5.2 Nexgen offers additional sessions for purchase.  If you buy more sessions during your study, any unused sessions remaining after completing your certification level will stay in your account and can be used at your discretion for future certificates, however, unused sessions are non-refundable.<br>
          5.3 Nexgen does not limit the frequency of booking an online coaching session with Nexgen trained coaches on the Application. However, for effective study, Nexgen strongly suggests you book a coaching session at least once every two weeks as suggested in the Nexgen Terms of Service above.<br>
          5.4 Supported Browsers can be found here: https://nexgenenglishonline.co/support<br>
        </div>
        6. These requirements listed above only apply to students that are following a self-study course. Students that are enrolled with an NAO that is employing Nexgen products will need to follow the requirements of that particular organization, which may differ from the above.<br>

        <br><b>Login Compliance with Privacy Regulations</b><br><br>
        neo Universal Logins and user identifications are formatted as emails, this is done to allow the logins to be easily remembered. For all self-study programs, Nexgen must verify all email addresses and mobile phone numbers through email and SMS. These logins are inherently unique globally.<br><br>
        SPECIAL NOTE: Organizations that are required to be regulation-compliant with FERPA, COPPA, and GDPR please use care and follow local government data regulations and use an email formatted login for all your students and staff in the Nexgen system.  Warning - your chosen login string format may already exist in our system globally, if it does you must select another formatted login string that is unique.  For advice on how to adjust your login string please contact your local Nexgen authorized representative or email us at support@nexgenenglishonline.co. <br><br>
      </p>
      <p class="eula">
        <br><b>Termination</b><br><br>
        This EULA shall remain in effect until terminated by you or Nexgen. Nexgen may, at its sole discretion, at any time and for any or no reason, suspend or terminate this EULA with or without prior notice. This EULA will terminate immediately, without prior notice from Nexgen, if you fail to comply with any provision of this EULA. You may also terminate this EULA by deleting the Application and all copies thereof from your mobile device or your computer. Upon termination of this EULA, you shall cease all use of the Application and delete all copies of the Application from your mobile device or your computer. Termination of this EULA will not limit any of Nexgen’s rights or remedies at law or in equity in case of breach by you of any of your obligations under the EULA.

        <br><br><b>Amendments to this Agreement</b><br><br>
        Nexgen reserves the right, at its sole discretion, to change, modify, add or replace portions of these Terms of Use at any time. It is your responsibility to check these Terms of Use periodically for changes. If a revision is a material change, Nexgen will provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at Nexgen’s sole discretion. By continuing to access or use Nexgen’s Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application and Termination (above) is in effect.

        <br><br><b>No Warranty</b><br><br>
        You expressly acknowledge and agree that the use of the Application is at your sole risk. To the maximum extent permitted by applicable law, the Application and any services performed or provided by the Application are provided “as is” and “as available,” with all faults and without warranty of any kind, and Nexgen hereby disclaims all warranties and conditions concerning the Application and any services, either express, implied, or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and noninfringement of third-party rights. No oral or written information or advice given by Nexgen or its authorized representatives shall create a warranty. Should the Application or service prove defective, you assume the entire cost of all necessary servicing, repair, or correction. Some jurisdictions do not allow the exclusion of implied warranties or limitations on applicable statutory rights of a consumer, so the above exclusion and limitations may not apply to you.

        <br><br><b>Limitation of Liability</b><br><br>
        To the extent not prohibited by law, in no event shall Nexgen be liable for personal injury or any incidental, special, indirect, or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss of data, business interruption, or any other commercial damages or losses, arising out of or related to your use of or inability to use the Application, however caused, regardless of the theory of liability (contract, tort, or otherwise) and even if Nexgen has been advised of the possibility of such damages. Some jurisdictions do not allow the limitation of liability for personal injury, or incidental or consequential damages, so this limitation may not apply to you. In no event shall Nexgen’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount equal to the purchase price of the Application license. The foregoing limitation will apply even if the above-stated remedy fails of its essential purpose.

        <br><br><b>Governing Law</b><br><br>
        The laws of California, United States, excluding its conflicts of law rules, shall govern this EULA and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.

        <br><br><b>Entire Agreement</b><br><br>
        The EULA constitutes the entire agreement between you and Nexgen regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Nexgen.

        <br><br><b>Contact Information</b><br><br>
        If you have questions about this Agreement, please contact us at legal@nexgenenglishonline.co  
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.eula {
  text-align: justify;

  b {
    font-weight: bold;
  }
}
</style>